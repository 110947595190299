import React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { Gimg } from '../components/gimg';
import { animation } from '../components/setting';

type MarksProps = {
	data: { nodes: any[] };
};

export const Marks = ({ data }: MarksProps) => {
	const params = {
		loop: true,
		slidesPerView: 5,
		spaceBetween: 50,
		speed: 1700,
		freeMode: true,
		freeModeMomentumBounce: false,
		freeModeMomentumRatio: 1,
		freeModeMomentumVelocityRatio: 0.5,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		grabCursor: true,
		navigation: {
			nextEl: '.swiper-button-next.swiper-button-black',
			prevEl: '.swiper-button-prev.swiper-button-black',
		},
		breakpoints: {
			500: {
				slidesPerView: 2,
			},
		},
	};
	return (
		<section>
			<h1 className="header--center">Nasze marki</h1>
			<Swiper {...params}>
				{data.nodes.map((el: any, i: number) => (
					<Slider key={el.name + i}>
						<img src={el.img.url} alt={el.name} />
					</Slider>
				))}
				{data.nodes.map((el: any, i: number) => (
					<Slider key={el.name + i}>
						<img src={el.img.url} alt={el.name} />
					</Slider>
				))}
			</Swiper>
		</section>
	);
};

const Slider = styled.div`
	text-align: center;
	align-self: center;

	img {
		/* width: 200px; */
		margin: 0 auto;
		max-height: 200px;
		max-width: 90%;
		transition: filter ${animation.slow};
		filter: saturate(0) brightness(1.1);
		&:hover {
			filter: saturate(1) brightness(1);
		}
	}
`;

const Container = styled.div``;
